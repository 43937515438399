




































































































































































































import { PageBase } from "@/core/models/shared";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Despesa } from "@/core/models/financeiro"
import { DespesaService, TipoDespesaService } from "@/core/services/financeiro"
import { FornecedorService } from "@/core/services/geral";
import { Fornecedor } from "@/core/models/geral";
import { EmbarcacaoService } from "@/core/services/operacional";
import { AlertExcludeQuestion, AlertSimpleErr } from "@/core/services/shared/AlertService";

@Component
export default class ListaProposta extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    excelDialog: boolean = false;
    excelOpcao: string = "padrao";
    totalLista: number = 0;
    lista: any[] = [];
    sheet: boolean = false;
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'RO', value: 'registroOperacional.numero', type: 'number' },
        { text: 'N° P.O.', value: 'pedidoCompra.numero', type: 'number' },
        { text: 'Data Atendimento', value: 'registroOperacional.atracacao.dataHoraEntrada', type:'date' },
        { text: 'Embarcação', value: 'registroOperacional.embarcacao.nome', sortable: false },
        { text: 'Fornecedor', value: 'fornecedor.nome' },
        { text: 'NF', value: 'notaFiscal' },
        { text: 'Fat', value: 'fatLocacao' },
        { text: 'Valor', value: 'totalGeral', sortable: false },
        { text: 'Data Emissão', value: 'dataEmissao', type: 'date' },
        { text: 'Data Pagamento', value: 'dataPagamento', type: 'date' },
        // { text: 'Quantidade', value: 'quantidade' },
        // { text: 'Tipo Imposto', value: 'tipoImposto' },
        { text: 'Tipo Despesa', value: 'tipo.nome' },
        { text: 'Equipamento/Serviço', value: 'equipamentoServico.nome' },

    ];
    filtroReceitaDespesa: any = {
        numeroRo: null,
        embarcacaoId: null,
        fornecedorId: null,
        tipoDespesaId: null,
        nf: null,
        dataAtendimentoInicio: null,
        dataAtendimentoFim: null,
        dataEmissaoInicio: null,
        dataEmissaoFim: null,
        dataInicio: null,
        dataFim: null,
    };

    item = new Despesa();
    service = new DespesaService();
    fornecedorService = new FornecedorService();
    fornecedores: Fornecedor[] = [];
    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;
    embarcacaoService = new EmbarcacaoService();
    embarcacoes: any[] = [];
    isEmbarcacaoLoading: boolean = false;
    onSearchEmbarcacao: any = null;
    tipoService = new TipoDespesaService();
    tipoDespesas: any[] = [];
    isTipoLoading: boolean = false;
    onSearchTipo: any = null;
    @Watch('options', { deep: true })
    Atualizar(isFiltro: boolean = true) {
        if (isFiltro == true) {
            this.options.page = 1;
        }
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.sheet = false;
        this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtroReceitaDespesa, "RegistroOperacional.Embarcacao, PedidoCompra, Fornecedor, Tipo,EquipamentoServico").then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => {
                AlertSimpleErr("Aviso", err)
            })
            .finally(() => (this.loading = false));
    }
    @Watch('onSearchFornecedor')
    SearchFornecedor(val: string) {
        if (this.isFornecedorLoading) return;
        if (this.filtroReceitaDespesa.fornecedorId) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.fornecedorService.AutoComplete(val)
            .then(
                res => {
                    this.fornecedores = res.data;
                },
                err => AlertSimpleErr("Aviso", err)
            )
            .finally(() => (this.isFornecedorLoading = false));
    }

    @Watch('onSearchEmbarcacao')
    SearchEmbarcacao(val: string) {
        if (this.isEmbarcacaoLoading) return;
        if (this.filtroReceitaDespesa.embarcacaoId) return;
        if (!val) return;
        this.isEmbarcacaoLoading = true
        this.embarcacaoService.AutoComplete(val)
            .then(
                res => {
                    this.embarcacoes = res.data;
                },
                err => AlertSimpleErr("Aviso", err)
            )
            .finally(() => (this.isEmbarcacaoLoading = false));
    }
    mounted() {
        this.tipoService.ListarTudo().then(
            res => {
                this.tipoDespesas = res.data.items;
                this.tipoDespesas.unshift(
                    {
                        id: null,
                        nome: "TODOS"
                    }
                )
            },
            err => AlertSimpleErr("Aviso", err)
        )
    }
    AbrirDialogCadastro(item?: Despesa) {
        if (item) {

            let include = "Fornecedor, Tipo, EquipamentoServico, PedidoCompra.Fornecedor, PedidoCompraEquipamento.Equipamento.Equipamento, PedidoCompraEquipamento.Equipamento.Fornecedor";
            
            if(item.registroOperacionalId)
                include += ", RegistroOperacional.Proposta.Cliente";

            this.service.ObterPorId(item.id, include).then(
                res => {
                    this.item = new Despesa(res.data);
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else {
            this.item = new Despesa();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }
    Imprimir() {
        let routeLink = this.$router.resolve({
            name: 'ralatorioDespesa',
            query: {
                numeroRo: this.filtroReceitaDespesa.numeroRo,
                fornecedorId: this.filtroReceitaDespesa.fornecedorId,
                embarcacaoId: this.filtroReceitaDespesa.embarcacaoId,
                tipoDespesaId: this.filtroReceitaDespesa.tipoDespesaId,
                dataInicio: this.filtroReceitaDespesa.dataInicio,
                dataFim: this.filtroReceitaDespesa.dataFim
            }
        });
        window.open(routeLink.href, '_blank');
    }
    GerarExcel(opcao: string) {
        if (opcao == "padrao") {
            this.ExportarExcel('tabela');
        }
        else {
            this.service.ExcelEquipamento(this.filtroReceitaDespesa.numeroRo, this.filtroReceitaDespesa.fornecedorId, this.filtroReceitaDespesa.embarcacaoId, this.filtroReceitaDespesa.tipoDespesaId, this.filtroReceitaDespesa.dataInicio, this.filtroReceitaDespesa.dataFim).then(
                res => {
                    this.JsonToCSV(res.data, "Despesas", true);
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        this.excelDialog = false;
    }
    Excluir(item: Despesa) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => AlertSimpleErr("Aviso", err)
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }
}
